<template>
  <div class="main">
    <div class="common-settings">
      <div class="title">
        <span>常用设置</span>
      </div>
      <div class="wrap">
        <div
          v-for="data in commonData"
          :key="data.type"
          class="common-apply"
        >
          <div class="apply-main">
            <div class="icon">
              <en-icon
                :name="data.icon"
                size="44"
              >
              </en-icon>
            </div>

            <p>{{ data.name }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeCommon",
  data() {
    return {
      commonData: [
        {
          name: "基础数据",
          type: 1,
          icon: "jichushuju-changyongrukoutubiao"
        },
        {
          name: "业务建模",
          type: 2,
          icon: "yewujianmo-changyongrukoutubiao"
        },
        {
          name: "流程建模",
          type: 3,
          icon: "flowmod"

        },
        {
          name: "分析建模",
          type: 4,
          icon: "baobiaoguanli-changyongrukoutubiao1"
        },
        {
          name: "管理建模",
          type: 5,
          icon: "moxingshezhi-changyongrukoutubiao"
        }
      ]
    };
  }
};

</script>

<style lang="scss" scoped>
.common-settings {
  height: 100%;
  border-radius: 5px;
  background-color: #fff;
  .title {
    height: 45px;
    line-height: 45px;
    text-align: left;
    border-bottom: 1px solid #f3f3f3;
    span {
      font-size: 14px;
      font-weight: bold;
      color: #333333;
      margin-left: 20px;
    }
  }
  .wrap {
    padding: 85px;
  }
}
.common-apply {
  float: left;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
  width: 200px;
  border: solid 1px #f3f3f3;
  border-radius: 15px;
  margin-right: 50px;
  margin-bottom: 50px;
  cursor: pointer;
  &:last-child {
    margin-right: 0;
  }
  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 90px;
    width: 90px;
    border-radius: 28px;
  }
  &:nth-child(5n-4) {
    .icon {
      background: rgb(70, 148, 223);
    }
  }
  &:nth-child(5n-3) {
    .icon {
      background: rgb(39, 195, 147);
    }
  }
  &:nth-child(5n-2) {
    .icon {
      background: rgb(255, 172, 44);
    }
  }
  &:nth-child(5n-1) {
    .icon {
      background: rgb(214, 158, 121);
    }
  }
  &:nth-child(5n) {
    .icon {
      background: rgb(144, 103, 235);
    }
  }
  &:hover {
    background: rgb(245, 247, 250);
  }
}
.el-header,
.el-main {
  padding: 0;
}
.main {
  height: 100%;
}
</style>

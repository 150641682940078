var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "main" }, [
    _c("div", { staticClass: "common-settings" }, [
      _vm._m(0),
      _c(
        "div",
        { staticClass: "wrap" },
        _vm._l(_vm.commonData, function (data) {
          return _c("div", { key: data.type, staticClass: "common-apply" }, [
            _c("div", { staticClass: "apply-main" }, [
              _c(
                "div",
                { staticClass: "icon" },
                [_c("en-icon", { attrs: { name: data.icon, size: "44" } })],
                1
              ),
              _c("p", [_vm._v(_vm._s(data.name))]),
            ]),
          ])
        }),
        0
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title" }, [
      _c("span", [_vm._v("常用设置")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }